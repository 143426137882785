<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/contract_manager/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewContractManager',
  components: {Form},
  data() {
    return {
      empty: {
        name: '',
        region: null,
        pica_fee_perc: 20,
        pica_fee_perc_during_stay: null,
        additional_fee_perc: 0,
        revenue_photographer_percent_after_pica: 100,
        revenue_organizer_percent_after_pica: 0,
        handling_fee: 0,
        assigns_transaction_costs_to_pica: false,
      }
    }
  },
  computed: {
    ...mapGetters('contractManager', [
      'contract_manager',
    ]),
  },
  methods: {
    ...mapActions('contractManager', [
      'createContractManager',
    ]),
    async onSave(data) {
      await this.createContractManager(data)
      await this.$router.push({name: 'contract_manager.detail', params: {id: this.contract_manager.id}})
      throw new SuccessNotification(this.$pgettext('contract_manager', 'Contract manager created!'))
    }
  }
}
</script>

<style scoped>

</style>