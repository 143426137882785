<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('contract_manager.label', 'Contract Manager Name')">
          <CInput v-model="form.name" :placeholder="$pgettext('contract_manager.placeholder','Name')"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Region')">
          <CSelect v-model="form.region" :options="regionOptions"/>
        </PFRow>
        <PFRow label="Pica fee perc"
               :helpText="$pgettext('plan.label','Add a fee (as a percentage, like 20) to the event\'s sales if \'Pica fee perc during stay\' is not set or if the payment is not made during the stay.')">
          <CInput v-model="form.pica_fee_perc" type="number"/>
        </PFRow>
        <PFRow label="Pica fee perc during stay"
               :helpText="$pgettext('plan.label','Add a fee percentage for sales during the event stay. If this field is left empty or if the payment is not made during the stay, the general \'Pica fee perc\' will be applied.')">
          <CInput v-model="form.pica_fee_perc_during_stay" type="number"/>
        </PFRow>
        <PFRow label="Additional fee perc"
               :helpText="$pgettext('plan.label','Additional fee (in percentage) to be added to the sales of the event.  Not expressed as decimal, but as percentage (e.g. 5)')">
          <CInput v-model="form.additional_fee_perc" type="number"/>
        </PFRow>
        <PFRow label="Revenue Photographer percent after pica"
               :helpText="$pgettext('plan.label','Percentuale all\'agenzia fotografica al netto di pica e costi')">
          <CInput v-model="form.revenue_photographer_percent_after_pica" type="number"/>
        </PFRow>
        <PFRow label="Revenue Organizer percent after pica"
               :helpText="$pgettext('plan.label','Percentuale all\'organizzatore al netto di pica e costi')">
          <CInput v-model="form.revenue_organizer_percent_after_pica" type="number"/>
        </PFRow>
        <PFRow label="Handling fee"
               :helpText="$pgettext('plan.label','Fixed fee (in currency) to be added to each order')">
          <CInput v-model="form.handling_fee" type="number"/>
        </PFRow>
        <PFRow label="Assign transaction costs to pica"
               :helpText="$pgettext('plan.label','Enable if the transaction costs are paid by Pica')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.assigns_transaction_costs_to_pica"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Form",
  components: {PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        region: null,
        pica_fee_perc: null,
        pica_fee_perc_during_stay: null,
        additional_fee_perc: null,
        revenue_photographer_percent_after_pica: null,
        revenue_organizer_percent_after_pica: null,
        handling_fee: null,
        assigns_transaction_costs_to_pica: false,
      },
    }
  },
  computed: {
    ...mapGetters('core', [
      'regions'
    ]),
    regionOptions() {
      return [{value: null, label: 'Select Region'}, ...this.regions]
    }
  },
  async mounted() {
    await this.fetchRegions()
    this.onReset()
  },
  emits: ['save'],
  methods: {
    ...mapActions('core', [
      'fetchRegions'
    ]),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>